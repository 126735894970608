import {ClientActivityModel} from 'src/app/components/clients/sub-components/client-edit/sub-components/client-activities/data/client-activity.model';
import {ClientProcessModel} from 'src/app/components/clients/sub-components/client-edit/sub-components/client-activities/data/client-process.model';
import {GRADIENTS} from 'src/app/core/gradiant-color-pallet';
import {GetContractResponseModel} from '../../../data/contact.types';
import {clientAssetsViewedModel} from './client-assets-viewed.model';
import {IClientStatus} from './client-status.model';
import {IClientSource} from './client-source.model';
import {IClientRequest} from './client-request.interface';
import {UserModel} from 'src/app/models/user.model';

export class ClientModel {
	_id?: string;
	id: string;
	userId: string;
	company_id: string;
	fullName: string = '';
	email: string = '';
	phone: string = '';
	address: string = '';
	birthday?: Date = null;
	id_number: number | null = null;
	contractsRef?: GetContractResponseModel[];
	preferences: ClientPreferences = new ClientPreferences();
	status: IClientStatus = null;
	isLead: boolean = false;
	type: ClientTypeEnum[] = [];
	assetsViewed?: clientAssetsViewedModel[] = [];
	createDate?: Date;
	activities?: ClientActivityModel[] = [];
	clientProcess?: ClientProcessModel[] = [];
	source?: IClientSource = null;

	userModel?: UserModel = null;

	matchingScore?: number = null;

	constructor(init?: Partial<IClient>, user?: UserModel, clientMatchingScore?: number) {
		if (init) {
			Object.assign(this, init);
			this.status = init?.status || null;
			this.source = init?.source || null;
			this.fullName = init?.fullName || '';
			this.email = init?.email || '';
			this.phone = init?.phone || '';
			this.address = init?.address || '';
			this.id_number = init?.id_number || null;
			if (this.assetsViewed) {
				this.assetsViewed = this.assetsViewed.map(viewed => new clientAssetsViewedModel(viewed));
			}
			this.createDate = new Date(init?.createDate);
			this.clientProcess = init?.clientProcess || [];

			if (user) {
				this.userModel = user;
			}

			if (clientMatchingScore) {
				this.matchingScore = clientMatchingScore;
			}
		}
	}
}

export interface IAssetViewed {
	assetId: string;
	viewedAt: string;
}

export class ClientPreferences {
	cities: string[] = [];
	streets: string[] = [];
	neighborhoods: string[] = [];
	assetTypes: string[] = [];
	budget?: number = null;
	assetSize: Range = new Range();
	floor: Range = new Range(0, 20);
	toilets: Range = new Range(0, 10);
	bedrooms: Range = new Range(0, 10);
	buildSize: Range = new Range(0, 1000);
	outsideSize: Range = new Range(0, 1000);
	salePrice: Range = new Range(0, 20000000);
	rentPrice: Range = new Range(0, 100000);
	assetProperties: string[] = [];
	assetDirections: string[] = [];
	description: string = '';
	subscriber: ClientSubscriberModel = new ClientSubscriberModel();
}

export class Range {
	min: number = 0;
	max: number = 0;

	constructor(min: number = 0, max: number = 0) {
		this.min = min;
		this.max = max;
	}
}

export class ClientSubscriberModel {
	enabled: boolean = false;
	expirationDate: Date = null;
	sendMessage: {
		email: boolean;
		sms: boolean;
		whatsapp: boolean;
	};
	target: string = '';
	name: string = '';
	lastRenewalDate: Date = null;
	notificationFrequency: NotificationFrequencyEnum = NotificationFrequencyEnum.IMMEDIATE;

	constructor(init?: Partial<ClientSubscriberModel>) {
		if (init) {
			Object.assign(this, init);
			this.expirationDate = new Date(init.expirationDate);
			this.lastRenewalDate = new Date(init.lastRenewalDate);
		}
	}
}

export enum NotificationFrequencyEnum {
	IMMEDIATE = 'immediate',
	DAILY = 'daily',
	WEEKLY = 'weekly',
}
export interface IClient {
	_id?: string;
	id: string;
	userId: string;
	company_id: string;
	fullName: string;
	email: string;
	phone: string;
	address?: string;
	birthday?: Date;
	id_number?: number;
	contractsRef?: GetContractResponseModel[];
	status: IClientStatus;
	isLead?: boolean;
	assetsViewed?: clientAssetsViewedModel[];
	createDate: Date;
	type: ClientTypeEnum[];
	activities?: ClientActivityModel[];
	clientProcess?: ClientProcessModel[];
	source?: IClientSource;
}

export enum ContractStatus {
	DRAFT = 'DRAFT',
	WAIT_TO_SIGN = 'WAIT_TO_SIGN',
	COMPLETED = 'COMPLETED',
	CANCELED = 'CANCELED',
	REJECTED = 'REJECTED',
	EXPIRED = 'EXPIRED',
}

export enum TranslatedContractStatusEnum {
	DRAFT = 'טיוטה',
	WAIT_TO_SIGN = 'ממתין לחתימה',
	COMPLETED = 'נחתם',
	CANCELED = 'בוטל',
	REJECTED = 'נדחה',
	EXPIRED = 'פג תוקף',
}

export enum ClientTypeEnum {
	Seller = 'Seller',
	Buyer = 'Buyer',
	Renter = 'Renter',
	Tenant = 'Tenant',
	Broker = 'Broker',
}

export enum ClientTypeTranslatedEnum {
	Seller = 'מוכר',
	Buyer = 'קונה',
	Renter = 'משכיר',
	Tenant = 'שוכר',
	Broker = 'מתווך',
}

/* -------------------------------------------------------------------------- */
/*                        Edit multi clients interface                        */
/* -------------------------------------------------------------------------- */

export interface UpdateClientData {
	status?: IClientStatus;
	isLead?: boolean;
}
