import {animate, state, style, transition, trigger} from '@angular/animations';
import {NgClass, NgFor} from '@angular/common';
import {Component, ElementRef, OnInit, Signal, computed, effect} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {AvatarModule} from 'primeng/avatar';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {DividerModule} from 'primeng/divider';
import {MenuModule} from 'primeng/menu';
import {SidebarModule} from 'primeng/sidebar';
import {UserModel} from 'src/app/models/user.model';
import {AuthService} from 'src/app/services/auth.service';
import {DeviceService} from 'src/app/services/device.service';
import {UserRoles} from '../clients copy/data/user.types';
import {AvatarComponent} from '../global/avatar/avatar.component';
import {CompanyModel} from '../signup/data/create-company.interface';
import {SidebarService} from './sidebar.service';

@Component({
	selector: 'sidebar',
	standalone: true,
	imports: [NgFor, NgClass, SidebarModule, ButtonModule, DividerModule, AvatarModule, RouterModule, AvatarComponent, CardModule, MenuModule],
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	animations: [
		trigger('slideUpDown', [
			state(
				'open',
				style({
					bottom: '10%',
					opacity: 1,
				}),
			),
			state(
				'close',
				style({
					bottom: '0',
					opacity: 0,
				}),
			),
			transition('open => close', [animate('2.5s ease-in-out')]),
			transition('close => open', [animate('2.5s ease-in-out')]),
		]),
	],
})
export class SidebarComponent implements OnInit {
	activeRoute: string | undefined;
	companyName: string = '';
	userName: string = '';
	protected UserRole = UserRoles;
	protected showSettingsPanel: boolean = false;
	protected sidebarRoutes: ISidebarRoute[] = [
		{
			label: 'דף הבית',
			icon: 'pi pi-chart-bar',
			routerLink: '/home',
			permission: UserRoles.User,
		},
		{
			label: 'החתמת הסכם חדש ',
			icon: 'pi pi-plus',
			routerLink: '/new-contract',
			permission: UserRoles.User,
		},
		{
			label: ' הוספת נכס ',
			icon: 'pi pi-plus',
			routerLink: '/new-property',
			permission: UserRoles.User,
		},
		{
			label: 'לידים',
			icon: 'pi pi-phone',
			routerLink: '/leads',
			permission: UserRoles.User,
		},
		{
			label: 'לקוחות',
			icon: 'pi pi-users',
			routerLink: '/clients',
			permission: UserRoles.User,
		},
		{
			label: 'נכסים',
			icon: 'pi pi-home',
			routerLink: '/properties',
			permission: UserRoles.User,
		},
		{
			label: 'התאמות',
			icon: 'fa-solid fa-wand-magic-sparkles',
			routerLink: '/find-your-match',
			permission: UserRoles.User,
			new: true,
		},
		{
			label: 'הסכמים',
			icon: 'pi pi-file',
			routerLink: '/contracts',
			permission: UserRoles.User,
		},
		// {
		// 	label: 'עסקאות',
		// 	icon: 'pi pi-briefcase',
		// 	routerLink: '/deals',
		// 	permission: UserRoles.User,
		// },
		{
			label: 'תבניות הסכמים',
			icon: 'pi pi-server',
			routerLink: '/contract-templates',
			permission: UserRoles.Admin,
		},
		{
			label: 'משתמשים',
			icon: 'pi pi-user',
			routerLink: '/users',
			permission: UserRoles.Admin,
		},
	];

	protected settingsItems: MenuItem[] = [];

	constructor(
		protected authService: AuthService,
		private router: Router,
		private elementRef: ElementRef,
		protected deviceService: DeviceService,
		protected sidebarService: SidebarService,
	) {
		effect(
			() => {
				const currentUser = this.authService.currentUser();
				const currentCompany = this.authService.currentCompany();
				if (currentUser() && currentCompany()) {
					this.userName = currentUser().fullname;
					this.userDate = new UserModel(currentUser());
					this.companyData = currentCompany();
				}
			},
			{allowSignalWrites: true},
		);
	}
	ngOnInit(): void {
		this.initializeSettingItemsMenu();
		this.filterRoutesBasedOnPermission();
		this.userDate = this.getUserData();
		if (this.deviceService.isDesktop() && this.authService.isLoggedIn) {
			this.openSidebar();
		}
		const company: CompanyModel = this.authService.getCompanyData();
		if (company) {
			if (company.name) this.companyName = company.name;
		}
	}

	private initializeSettingItemsMenu(): void {
		if (this.authService.isAdminUser()) {
			this.settingsItems = [
				{
					label: 'פרופיל',
					icon: 'pi pi-user px-2',
					command: () => {
						this.navigate('/profile');
					},
				},
				{
					label: 'הגדרות',
					icon: 'pi pi-cog px-2',
					command: () => {
						this.navigate('/settings');
					},
				},
				{separator: true},
				{
					label: 'התנתק',
					icon: 'pi pi-sign-out px-2',
					command: () => {
						this.authService.logout();
					},
				},
			];
		} else {
			this.settingsItems = [
				{
					label: 'פרופיל',
					icon: 'pi pi-user px-2',
					command: () => {
						this.navigate('/profile');
					},
				},
				{separator: true},
				{
					label: 'התנתק',
					icon: 'pi pi-sign-out px-2',
					command: () => {
						this.authService.logout();
					},
				},
			];
		}
	}
	private filterRoutesBasedOnPermission(): void {
		const userRole = this.authService.getUserData()?.role;
		if (userRole) {
			this.sidebarRoutes = this.sidebarRoutes.filter(
				route => route.permission === UserRoles.User || (route.permission === UserRoles.Admin && userRole === UserRoles.Admin),
			);
		}
	}

	protected userDate: UserModel = null;
	protected companyData: CompanyModel = null;

	protected isAuth: Signal<boolean> = computed(() => {
		const currentUser = this.authService.currentUser$();
		return currentUser ? true : false;
	});

	// @HostListener('document:click', ['$event'])
	// onDocumentClick(event: MouseEvent) {
	// 	// Check if the click is outside of the sidebar element
	// 	if (!this.elementRef.nativeElement.contains(event.target)  && this.deviceService.isMobile()) {
	// 		this.closeSidebar();
	// 	}
	// }

	navigate(route: string | undefined) {
		if (route) {
			this.router.navigate([route]);
			this.activeRoute = route;
			if (this.deviceService.isMobile()) {
				this.closeSidebar();
			}
			this.sidebarService.loadFromServer.set(true);
		}
	}

	private getUserData(): UserModel {
		return this.authService.getUserData();
	}

	openSettingPanel() {
		this.showSettingsPanel = !this.showSettingsPanel;
	}

	openSidebar() {
		this.sidebarService.sidebarVisible.set(true);
	}

	closeSidebar() {
		//this.sidebarVis = false;
		this.sidebarService.sidebarVisible.set(false);
		this.showSettingsPanel = false;
	}
}

export interface ISidebarRoute {
	label: string;
	icon: string;
	routerLink?: string;
	routerLinkActive?: string;
	items?: ISidebarRoute[];
	permission: UserRoles;
	new?: boolean;
}
